html {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y:auto;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: auto;
  user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
  outline: none;
}

.root {
  width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
  height: 5px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #eec87b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2b2b2b;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill {
  box-shadow:inset 0 0 0px 9999px white;
-webkit-box-shadow: inset 0 0 0px 9999px white;
}
